import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import '../css/icon.less';

/** mapping between icon name and svg file name in assets folder */
const ICON_MAP = {
    check: { folder: 'BASICS', file: 'icon_check_xs' },
    x: { folder: 'BASICS', file: 'icon_x_s' },
    plus: { folder: 'BASICS', file: 'icon_plus_s' },
    minus: { folder: 'BASICS', file: 'icon_minus_s' },
    plus_m: { folder: 'BASICS', file: 'icon_plus_m' },
    minus_m: { folder: 'BASICS', file: 'icon_minus_m' },
    check_circle: { folder: 'BASICS', file: 'icon_check_circle_s' },
    x_circle: { folder: 'BASICS', file: 'icon_x_circle_s' },
    plus_circle: { folder: 'BASICS', file: 'icon_plus_circle_s' },
    minus_circle: { folder: 'BASICS', file: 'icon_minus_circle_s' },
    checkbox: { folder: 'BASICS', file: 'icon_check_square_s' },
    x_square: { folder: 'BASICS', file: 'icon_x_square_m' },
    x_close_search: { folder: 'BASICS', file: 'icon_x_close_search'},
    x_clear_search: { folder: 'BASICS', file: 'icon_x_clear_search'},
    plus_square: { folder: 'BASICS', file: 'icon_plus_square_m' },
    minus_square: { folder: 'BASICS', file: 'icon_minus_square_m' },
    double_left: { folder: 'ARROWS', file: 'icon_chevron_double_left_s' },
    double_right: { folder: 'ARROWS', file: 'icon_chevron_double_right_s' },
    arrow_left: { folder: 'ARROWS', file: 'icon_arrow_left_s' },
    arrow_right: { folder: 'ARROWS', file: 'icon_arrow_right_s' },
    left: { folder: 'ARROWS', file: 'icon_chevron_left_m' },
    right: { folder: 'ARROWS', file: 'icon_chevron_right_m' },
    down: { folder: 'ARROWS', file: 'icon_chevron_down_m' },
    up: { folder: 'ARROWS', file: 'icon_chevron_up_m' },
    left_small: { folder: 'ARROWS', file: 'icon_chevron_left_s' },
    right_small: { folder: 'ARROWS', file: 'icon_chevron_right_s' },
    down_small: { folder: 'ARROWS', file: 'icon_chevron_down_s' },
    up_small: { folder: 'ARROWS', file: 'icon_chevron_up_s' },
    left_circle: { folder: 'ARROWS', file: 'icon_chevron_left_circle_s' },
    right_circle: { folder: 'ARROWS', file: 'icon_chevron_right_circle_s' },
    up_circle: { folder: 'ARROWS', file: 'icon_chevron_up_circle_s' },
    down_circle: { folder: 'ARROWS', file: 'icon_chevron_down_circle_s' },
    left_square: { folder: 'ARROWS', file: 'icon_chevron_left_square_s' },
    right_square: { folder: 'ARROWS', file: 'icon_chevron_right_square_s' },
    up_square: { folder: 'ARROWS', file: 'icon_chevron_up_square_s' },
    down_square: { folder: 'ARROWS', file: 'icon_chevron_down_square_s' },
    menu: { folder: 'INTERACTIVE', file: 'icon_hamburger_m' },
    search: { folder: 'INTERACTIVE', file: 'icon_search_m' },
    cart_filled: { folder: 'INTERACTIVE', file: 'icon_cart_filled_m' },
    cart: { folder: 'INTERACTIVE', file: 'icon_cart_m' },
    account_filled: { folder: 'INTERACTIVE', file: 'icon_account_filled' },
    account: { folder: 'INTERACTIVE', file: 'icon_account_m' },
    support: { folder: 'INTERACTIVE', file: 'icon_support_m' },
    bottom_line: { folder: 'INTERACTIVE', file: 'icon_bottom_minus_m' },
    download: { folder: 'INTERACTIVE', file: 'icon_download_m' },
    copy: { folder: 'INTERACTIVE', file: 'icon_copy_clipboard_m' },
    link: { folder: 'INTERACTIVE', file: 'icon_link_s' },
    heart_filled: { folder: 'INTERACTIVE', file: 'icon_heart_filled_m' },
    header: { folder: 'INTERACTIVE', file: 'icon_heart_m' },
    share_filled: { folder: 'INTERACTIVE', file: 'icon_share_filled_m' },
    share: { folder: 'INTERACTIVE', file: 'icon_share_m' },
    sound_on: { folder: 'INTERACTIVE', file: 'icon_sound_on_m' },
    sound_off: { folder: 'INTERACTIVE', file: 'icon_sound_off_m' },
    play: { folder: 'INTERACTIVE', file: 'icon_play_m' },
    pause: { folder: 'INTERACTIVE', file: 'icon_pause_m' },
    zoom: { folder: 'INTERACTIVE', file: 'icon_image_zoom_m' },
    reset: { folder: 'INTERACTIVE', file: 'icon_image_reset_m' },
    zoom_in: { folder: 'INTERACTIVE', file: 'icon_zoom_in_m' },
    zoom_out: { folder: 'INTERACTIVE', file: 'icon_zoom_out_m' },
    bestseller: { folder: 'TAGS', file: 'icon_bestseller' },
    experts_recommend: { folder: 'TAGS', file: 'icon_experts_recommend'},
    help: { folder: 'TAGS', file: 'icon_help_m' },
    payment: { folder: 'TAGS', file: 'icon_payment_m' },
    quote: { folder: 'TAGS', file: 'icon_quote_m' },
    shipping: { folder: 'TAGS', file: 'icon_shipping_m' },
    ink_double_drop: { folder: 'TAGS', file: 'icon_ink_double_drop' },
    ink_filled: { folder: 'TAGS', file: 'icon_ink_drop_m' },
    ink: { folder: 'TAGS', file: 'icon_ink_drop_outline_m' },
    toner: { folder: 'TAGS', file: 'icon_toner' },
    billing: { folder: 'STATUS', file: 'icon_billing_m' },
    shipping_checked: { folder: 'STATUS', file: 'icon_shipping_checked_m' },
    soldto: { folder: 'STATUS', file: 'icon_soldto_m' },
    warning: { folder: 'STATUS', file: 'icon_warning_m' },
    star_filled: { folder: 'COMPONENT SPECIFIC', file: 'icon_star_filled_m' },
    star: { folder: 'COMPONENT SPECIFIC', file: 'icon_star_m' },
    vote: { folder: 'COMPONENT SPECIFIC', file: 'icon_vote_up_m' },
    vote_down: { folder: 'COMPONENT SPECIFIC', file: 'icon_vote_down_m' },
    tooltip: { folder: 'COMPONENT SPECIFIC', file: 'icon_tooltip_m' },
    facebook: { folder: 'COMPONENT SPECIFIC', file: 'icon_facebook_m' },
    twitter: { folder: 'COMPONENT SPECIFIC', file: 'icon_twitter_m' },
    youtube: { folder: 'COMPONENT SPECIFIC', file: 'icon_youtube_m' },
    bags_cases: { folder: 'ACCESSORIES', file: 'icon_bags_cases' },
    batteries: { folder: 'ACCESSORIES', file: 'icon_batteries' },
    business: { folder: 'ACCESSORIES', file: 'icon_business' },
    cables: { folder: 'ACCESSORIES', file: 'icon_cables' },
    calculators: { folder: 'ACCESSORIES', file: 'icon_calculators' },
    care_packs: { folder: 'ACCESSORIES', file: 'icon_care_packs' },
    connectivity: { folder: 'ACCESSORIES', file: 'icon_connectivity' },
    docking: { folder: 'ACCESSORIES', file: 'icon_docking' },
    gaming: { folder: 'ACCESSORIES', file: 'icon_gaming' },
    ink_cartridges: { folder: 'ACCESSORIES', file: 'icon_ink_cartridges'},
    mice_keyboards: { folder: 'ACCESSORIES', file: 'icon_mice_keyboards' },
    monitors: { folder: 'ACCESSORIES', file: 'icon_monitors' },
    multimedia: { folder: 'ACCESSORIES', file: 'icon_multimedia' },
    paper: { folder: 'ACCESSORIES', file: 'icon_paper' },
    printing_accessories: { folder: 'ACCESSORIES', file: 'icon_printing_accessories' },
    printhead: { folder: 'ACCESSORIES', file: 'icon_printhead'},
    smart_home: { folder: 'ACCESSORIES', file: 'icon_smarthome' },
    software: { folder: 'ACCESSORIES', file: 'icon_software' },
    stemandkids: { folder: 'ACCESSORIES', file: 'icon_stemandkids' },
    storage: { folder: 'ACCESSORIES', file: 'icon_storage' },
    workspace: { folder: 'ACCESSORIES', file: 'icon_workspace'}
};

const Icon = ({ name, size, className, onClick, onKeyDown, iconProps }) => {
    const ImportedIconRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const iconInfo = ICON_MAP[name] || ICON_MAP['warning'];

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const importIcon = async () => {
            try {
                const { default: namedImport } = await import(
                    `../../assets/svg/${iconInfo.folder}/${iconInfo.file}.svg`
                );
                ImportedIconRef.current = namedImport;
            } catch (err) {
                console.log('Error loading svg.', err);
            } finally {
                if(mounted){
                    setLoading(false);
                }
            }
        };
        importIcon();
        return () => mounted = false;
    }, [name]);

    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        const iconStyle = typeof size === 'number'
                           ? {
                                width: `${size}px`,
                                height: `${size}px`
                             }
                           : {};
                    
        return <ImportedIcon className={className} style={iconStyle} onClick={onClick} onKeyDown={onKeyDown} {...iconProps || {}}/>;
    }

    return null;
};

Icon.propTypes = {
    /**Name used to map to the desired icon */
    name: PropTypes.string,
    /**size in pixels for height and width */
    size: PropTypes.number,
    className: PropTypes.string,
};

Icon.defaultProps = {
    name: 'warning',
    size: 25,
    className: 'icon',
};

export default Icon;
